import React from "react"
import { useSpring, useTrail, animated, config } from "react-spring"
import { graphql, StaticQuery, Link } from "gatsby"
import styled, { css } from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import SocialList from "./../SocialList"

const StyledMenu = styled(animated.aside)`
  position: fixed;

  top: 0;
  left: 0;

  transform: translateX(-100%);

  z-index: 4;

  width: 100%;
  height: 100%;
`

const Inner = styled.div`
  display: block;

  width: 100%;
  height: 100%;

  background-color: ${({ background }) => background};

  padding: ${props => props.theme.mobileVW(90)}
    ${props => props.theme.mobileVW(30)};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    width: 40%;

    padding: 14vh ${props => props.theme.desktopVW(100)};
  }
`

const Outer = styled.div`
  position: absolute;

  top: 0;
  right: 0;

  width: 60%;
  height: 100%;
`

const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
`

const MenuItem = styled(animated.li)`
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-left: 0;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: 0.75rem;
  }
`

const LinkStyles = css`
  color: ${props => props.theme.colors.white};

  line-height: 1.5;

  opacity: 0.5;

  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);

  cursor: pointer;

  &:hover,
  &.active {
    opacity: 1;
  }
`

const NormalLink = styled(AniLink)`
    ${LinkStyles}

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1.25rem;

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        font-size: 1.5rem;
    }
`

const SubLink = styled(AniLink)`
    ${LinkStyles}

    margin-left: ${props => props.theme.mobileVW(20)};

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1rem;

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        margin-left: ${props => props.theme.desktopVW(30)};
    }
`

const Menu = ({ menuOpen, menuState, menuColor }) => {
  const toggleMenu = state => {
    menuState(state)
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          menu: contentfulThemeMenu {
            items {
              name
              slug
              type
            }
          }
          social: allContentfulThemeSocial {
            edges {
              node {
                name
                link
              }
            }
          }
        }
      `}
      render={query => {
        const {
          menu: { items },
        } = query
        const config = { mass: 5, tension: 2000, friction: 200 }

        const menuAnimation = useSpring({
          config: config.wobbly,
          transform: menuOpen ? "translateX(0%)" : "translateX(-100%)",
        })

        const itemsAnimation = useTrail(items.length, {
          config,
          opacity: menuOpen ? 1 : 0,
          x: menuOpen ? 0 : -10,
          from: {
            opacity: 0,
            x: -10,
          },
        })

        const socialAnimation = useSpring({
          delay: 250,
          opacity: menuOpen ? 1 : 0,
        })

        return (
          <StyledMenu style={menuAnimation}>
            <Inner background={menuColor}>
              <MenuList>
                {itemsAnimation.map(({ x, ...rest }, index) => (
                  <MenuItem
                    key={index}
                    type={items[index].type.toLowerCase()}
                    style={{
                      ...rest,
                      transform: x.interpolate(x => `translate3d(${x}px,0,0)`),
                    }}
                  >
                    {items[index].type == "Normal" ? (
                      <NormalLink
                        to={items[index].slug}
                        activeClassName="active"
                        onClick={() => {
                          toggleMenu(false)
                        }}
                        cover
                        direction="right"
                        bg={menuColor}
                      >
                        {items[index].name}
                      </NormalLink>
                    ) : (
                      <SubLink
                        to={items[index].slug}
                        activeClassName="active"
                        onClick={() => {
                          toggleMenu(false)
                        }}
                        cover
                        direction="right"
                        bg={menuColor}
                      >
                        {items[index].name}
                      </SubLink>
                    )}
                  </MenuItem>
                ))}
              </MenuList>
              <animated.div style={socialAnimation}>
                <SocialList data={query.social} />
              </animated.div>
            </Inner>
            <Outer onClick={() => toggleMenu(false)} />
          </StyledMenu>
        )
      }}
    />
  )
}

export default Menu
