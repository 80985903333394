import React from 'react'

const Arrow = ({ color, styles }) => {
    return (
        <svg className='arrow' styles={styles} xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 21'>
            <path fill={color} d='M21.4,9.7L12.7,1l0.9-1L24,10.4L13.6,20.7l-0.9-1l8.8-8.7H0V9.7L21.4,9.7L21.4,9.7z' />
        </svg>
    )
}

export default Arrow