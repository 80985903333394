import React, { useEffect, useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useSpring, animated } from "react-spring"
import styled, { css } from "styled-components"
import theme from "./../../styles/theme"

import ArrowSvg from "./../Icons/Arrow"

const ButtonStyles = css`
  position: relative;

  color: ${({ color }) => (color ? color : "currentColor")};
`

const StyledInternal = styled(AniLink)`
  ${ButtonStyles}
`

const StyledAnchor = styled.button`
  ${ButtonStyles}
`

const StyledExternal = styled.a`
  ${ButtonStyles}

  opacity: 1;
`

const Label = styled.span`
  color: currentColor;
`

const ArrowWrapper = styled(animated.span)`
  display: inline-block;

  position: relative;

  width: ${props => props.theme.mobileVW(15)};

  margin-left: ${props => props.theme.mobileVW(10)};

  .arrow {
    path {
      fill: currentColor;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    width: ${props => props.theme.desktopVW(15)};

    margin-left: ${props => props.theme.desktopVW(10)};
  }
`

const ButtonInternal = ({ label, to, href, color, onClick }) => {
  const [isHovered, setHovered] = useState(false)
  const [menuColor, setMenuColor] = useState("#000")

  const props = useSpring({
    transform: isHovered ? "translateX(10px)" : "translateX(0px)",
  })

  useEffect(() => {
    setMenuColor(
      getComputedStyle(document.body).getPropertyValue("--menu-color")
    )
  }, [])

  if (href) {
    return (
      <StyledExternal
        href={href}
        target="_blank"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        color={color && color}
        className="button-internal"
      >
        <Label>{label}</Label>
        <ArrowWrapper style={props} className="button-internal__arrow-wrapper">
          <ArrowSvg color={color && color} />
        </ArrowWrapper>
      </StyledExternal>
    )
  } else if (onClick) {
    return (
      <StyledAnchor
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        color={color && color}
        className="button-internal"
        onClick={onClick}
      >
        <Label>{label}</Label>
        <ArrowWrapper style={props} className="button-internal__arrow-wrapper">
          <ArrowSvg color={color && color} />
        </ArrowWrapper>
      </StyledAnchor>
    )
  } else {
    return (
      <StyledInternal
        to={to}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        color={color && color}
        className="button-internal"
        cover
        direction="right"
        bg={menuColor}
      >
        <Label>{label}</Label>
        <ArrowWrapper style={props} className="button-internal__arrow-wrapper">
          <ArrowSvg color={color && color} />
        </ArrowWrapper>
      </StyledInternal>
    )
  }
}

export default ButtonInternal
