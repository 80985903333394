import React from "react"
import { useSpring, animated } from "react-spring"
import styled from "styled-components"

const StyledButtonMenu = styled.button`
    // position: absolute;
    position: relative;

    // top: ${props => props.theme.space.paddingMobile};
    // left: ${props => props.theme.space.paddingMobile};

    width: ${props => props.theme.mobileVW(20)};
    height: ${props => props.theme.mobileVW(12)};

    cursor: pointer;

    color: ${props => props.color};
    /* color: ${props => props.theme.colors.black}; */

    ${({ open }) =>
      open &&
      `
        color: #fff;

        span {
            &:nth-of-type(1) {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                transition-delay: 0.25s;
            }

            &:nth-of-type(2) {
                opacity: 0;
                left: 20px;
            }

            &:nth-of-type(3) {
                bottom: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                transition-delay: 0.25s;
            }
        }
    `}

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        // position: relative;

        // top: ${props => props.theme.desktopVW(85)};
        // left: ${props => props.theme.desktopVW(100)};

        width: 30px;
        height: 20px;

        width: ${props => props.theme.desktopVW(30)};
        height: ${props => props.theme.desktopVW(20)};
    }
`

const Line = styled.span`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 1px;

  background-color: currentColor;

  transition: all 0.15s ease-out;

  &:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
  }

  &:nth-of-type(3) {
    top: auto;
    bottom: 0;
  }
`

const ButtonMenu = ({ color, menuState, menuOpen }) => {
  const toggleMenu = state => {
    menuState(state)
  }

  return (
    <StyledButtonMenu
      className="button-menu"
      onClick={() => {
        toggleMenu(!menuOpen)
      }}
      open={menuOpen}
      color={color}
    >
      <Line />
      <Line />
      <Line />
    </StyledButtonMenu>
  )
}

export default ButtonMenu
