import React from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

const CarouselWrapper = styled.div`
  display: block;

  margin-bottom: calc(${props => props.theme.space.paddingMobile} / 2);

  .alice-carousel__dots-item {
    background-color: ${props => props.theme.colors.blue};
    opacity: 0.25;

    &.__active {
      opacity: 1;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: none;
  }
`

const ListWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: block;
  }
`

const List = styled.ul`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: ${props => props.theme.space.paddingMobile};

  width: 100%;

  margin-bottom: ${props => props.theme.space.paddingMobile};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    grid-template-columns: auto auto auto;
    grid-gap: ${props => props.theme.desktopVW(20)};

    margin-bottom: ${props => props.theme.desktopVW(40)};
  }
`

const Item = styled.div``

const StyledLink = styled.a`
  img {
    transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`

const InstagramFeed = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulThemeMenu(limit: 6) {
          nodes {
            instagramImages {
              fluid(maxHeight: 500, maxWidth: 500, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <CarouselWrapper>
            <ScrollAnimation animateIn="fadeIn" delay={100} duration={1.5}>
              <AliceCarousel
                buttonsDisabled={true}
                dotsDisabled={true}
                duration={250}
                autoPlayInterval={7500}
                autoPlay={true}
                fadeOutAnimation={true}
              >
                {data.allContentfulThemeMenu.nodes[0].instagramImages.map(
                  (item, index) => (
                    <Item key={index}>
                      <StyledLink
                        href="https://www.instagram.com/sabinedevoogd/"
                        target="_blank"
                      >
                        <Img fluid={item.fluid} />
                      </StyledLink>
                    </Item>
                  )
                )}
              </AliceCarousel>
            </ScrollAnimation>
          </CarouselWrapper>
          <ListWrapper>
            <List>
              {data.allContentfulThemeMenu.nodes[0].instagramImages.map(
                (item, index) => (
                  <Item key={index}>
                    <StyledLink
                      href="https://www.instagram.com/sabinedevoogd/"
                      target="_blank"
                    >
                      <ScrollAnimation
                        animateIn="fadeIn"
                        delay={index * 100}
                        duration={1.5}
                      >
                        <Img fluid={item.fluid} />
                      </ScrollAnimation>
                    </StyledLink>
                  </Item>
                )
              )}
            </List>
          </ListWrapper>
        </>
      )
    }}
  />
)

export default InstagramFeed
