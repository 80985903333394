import React, { useState, useEffect } from "react"
import styled from "styled-components"

import Transition from "./Transition"
import GlobalStyle from "./GlobalStyle"

import Header from "./Header"
import Menu from "./Menu"
import Footer from "./Footer"

export const Main = styled.main`
  position: relative;

  width: 100%;
  height: 100%;
`

const Line = styled.span`
	display: none;
	
	position: fixed;

	z-index: 2;

	width: 1px;
	height: 100vh;

	background-color: ${props => props.color};
	/* background-color: ${props => props.theme.colors.black}; */
	opacity: 0.15;

	transition: all 0.35s ease-in-out;

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
		display: block;

		top: ${props => props.theme.desktopVW(150)};
		left: ${props => props.theme.desktopVW(115)};
    }
`

const Layout = ({ children, footer, menu, colors, rootsmentLogo }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [headerColor, setHeaderColor] = useState(colors?.header || "#000")
  const [windowHeight, setWindowHeight] = useState(0)

  const toggleMenu = menuState => {
    setMenuOpen(menuState)
  }

  const handleScroll = e => {
    if (window.innerWidth <= 1023) return

    const scrollTop = e.target.documentElement.scrollTop

    if (colors.header == "#000") return

    if (scrollTop > windowHeight / 2) {
      if (headerColor != "#000") setHeaderColor("#000")
    } else {
      setHeaderColor("#fff")
    }
  }

  useEffect(() => {
    setWindowHeight(typeof window !== "undefined" && window.innerHeight)
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <GlobalStyle
        bodyColor={colors?.page || "#fff"}
        shouldDisableScroll={menuOpen}
        menuColor={menu?.backgroundColor.color}
      />
      <Header
        color={headerColor}
        menuState={toggleMenu}
        menuOpen={menuOpen}
        menuColor={menu?.backgroundColor.color}
        rootsmentLogo={rootsmentLogo}
      />
      <Menu
        data={menu}
        menuOpen={menuOpen}
        menuState={toggleMenu}
        menuColor={menu?.backgroundColor?.color}
      />
      <Line color={headerColor} />
      <Main>{children}</Main>
      <Footer data={footer} />
    </>
  )
}

export default Layout
