import React from "react"
import Provider from "./src/components/Layout/Provider"
import Layout from "./src/components/Layout/Layout"

export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>

export const wrapPageElement = ({
  element,
  props: {
    location,
    pageContext: { footer, menu, colors, rootsmentLogo },
  },
}) => {
  return (
    <Layout
      colors={colors}
      footer={footer}
      menu={menu}
      location={location}
      rootsmentLogo={rootsmentLogo}
    >
      {element}
    </Layout>
  )
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), 500)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 500)
  }
  return false
}
