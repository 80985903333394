// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-journal-js": () => import("./../../../src/templates/Journal.js" /* webpackChunkName: "component---src-templates-journal-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/Portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/Service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

