import { css } from "styled-components"

const mobileVW = px => {
  return `${(px / 375) * 100}vw`
}

const desktopVW = px => {
  return `${(px / 1800) * 100}vw`
}

const letterSpacing = space => {
  return `${(space / 1000) * 1}em`
}

const breakpoints = {
  desktop: "1023px",
}

const colors = {
  white: "#fff",
  black: "#000",
  lightGrey: "#fafafa",
  mediumGrey: "#E9E9E9",
  grey: "#AFAFAF",
  darkGrey: "#2b2b2b",
  blue: "#56737C",
}

const space = {
  paddingMobile: mobileVW(30),
  paddingMobileLarge: mobileVW(45),
  paddingDesktop: desktopVW(250),
  maxContentWidthMobile: mobileVW(1400),
  maxContentWidthDesktop: desktopVW(1280),
  maxContentWidthXlDesktop: desktopVW(1700),
}

const fontFamily = {
  brandonLight: "BrandonGrotesqueLight",
  brandonMedium: "BrandonGrotesqueMedium",
  brandonRegular: "BrandonGrotesqueRegular",
  portraitLight: "PortraitLight",
  portraitLightItalic: "PortraitLightItalic",
  portraitRegular: "PortraitRegular",
  portraitRegularItalic: "PortraitRegularItalic",
  portraitMedium: "PortraitMedium",
  portraitMediumItalic: "PortraitMediumItalic",
  circularBook: "CircularBook",
}

const fontSize = {
  d1: desktopVW(75),
  d2: desktopVW(55),
  d3: desktopVW(40),
  d4: desktopVW(35),
  d5: desktopVW(30),
  d6: desktopVW(25),
  d7: desktopVW(20),
  d8: desktopVW(15),
  m1: mobileVW(35),
  m2: mobileVW(35),
  m3: mobileVW(30),
  m4: mobileVW(25),
  m5: mobileVW(20),
  m6: mobileVW(15),
  m7: mobileVW(12),
}

const newFontSize = {
  d1: desktopVW(50),
  d2: desktopVW(45),
  d3: desktopVW(40),
  d4: desktopVW(35),
  d5: desktopVW(30),
  d6: desktopVW(25),
  d7: desktopVW(20),
  d8: desktopVW(15),
  dp: desktopVW(18),
  m1: mobileVW(30),
  m2: mobileVW(25),
  m3: mobileVW(20),
  m4: mobileVW(18),
  m5: mobileVW(15),
  m6: mobileVW(14),
  m7: mobileVW(12),
  mp: mobileVW(12),
}

const headerHeight = {
  mobile: "13.0666666667vw",
  desktop: "6.9vw",
}

const easeInOut = {
  sine: "cubic-bezier(0.445, 0.05, 0.55, 0.95)",
  quad: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
  cubic: "cubic-bezier(0.645, 0.045, 0.355, 1)",
  quart: "cubic-bezier(0.77, 0, 0.175, 1)",
  quint: "cubic-bezier(0.86, 0, 0.07, 1)",
  expo: "cubic-bezier(1, 0, 0, 1)",
}

const easeIn = {
  sine: "cubic-bezier(0.47, 0, 0.745, 0.715)",
  quad: "cubic-bezier(0.55, 0.085, 0.68, 0.53)",
  cubic: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
  quart: "cubic-bezier(0.895, 0.03, 0.685, 0.22)",
  quint: "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
  expo: "cubic-bezier(0.95, 0.05, 0.795, 0.035)",
}

const easeOut = {
  sine: "cubic-bezier(0.39, 0.575, 0.565, 1)",
  quad: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
  cubic: "cubic-bezier(0.215, 0.61, 0.355, 1)",
  quart: "cubic-bezier(0.165, 0.84, 0.44, 1)",
  quint: "cubic-bezier(0.23, 1, 0.32, 1)",
  expo: "cubic-bezier(0.19, 1, 0.22, 1)",
}

const generateUnderlineAnimation = color => css`
  position: relative;
  color: inherit;

  &::after {
    content: "";
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    width: 0;
    height: 1px;
    background: ${color};
    transition: width 0.125s;
  }

  @media (min-width: 1024px) {
    &:hover::after {
      width: 100%;
    }
  }
`

const setUnderline = color => css`
  &::after {
    content: "";
    position: absolute;

    bottom: -3px;
    left: 0;

    width: 100%;
    height: 1px;

    background-color: ${color};

    transform: scaleX(0);
    transition: none;
    transform-origin: left;
  }

  &:hover::after {
    transform: scaleX(1);
    transition: transform 0.2s ease-out;
  }
`

const theme = {
  breakpoints,
  colors,
  space,
  fontFamily,
  fontSize,
  newFontSize,
  headerHeight,
  easeInOut,
  easeIn,
  easeOut,
  mobileVW,
  desktopVW,
  letterSpacing,
  generateUnderlineAnimation,
  setUnderline,
}

export default theme
