import React from "react"
import styled from "styled-components"

import ButtonMenu from "./../Buttons/ButtonMenu"
import Logo from "./../Logo"

const StyledHeader = styled.header`
  position: fixed;

  top: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: calc(${props => props.theme.space.paddingMobile} / 1.5)
    ${props => props.theme.space.paddingMobile};
  padding-bottom: 0;

  z-index: 5;

  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: ${props => props.theme.desktopVW(80)}
      ${props => props.theme.desktopVW(100)};
    padding-bottom: 0;
  }
`

const Header = ({ color, menuState, menuOpen, rootsmentLogo, menuColor }) => {
  return (
    <StyledHeader>
      <ButtonMenu
        color={color}
        menuState={menuState}
        menuOpen={menuOpen}
        menuColor={menuColor}
      />
      <Logo
        color={color}
        menuOpen={menuOpen}
        rootsmentLogo={rootsmentLogo}
        menuColor={menuColor}
      />
    </StyledHeader>
  )
}

export default Header
