import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled, { css } from "styled-components"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import Text from "./../Text"
import InstagramFeed from "./../InstagramFeed"
import Beeldmerk from "../Icons/Beeldmerk"

const StyledFooter = styled.footer`
  position: relative;

  z-index: 3;

  background-color: ${({ background }) => background};

  // @media screen and (min-width: ${props =>
    props.theme.breakpoints.desktop}) {
  //   padding-top: ${props => props.theme.desktopVW(200)};
  // }
`

const Banner = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  background-color: #fff;
  color: #fff;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: ${props => props.theme.desktopVW(200)};

    display: flex;
    align-items: center;
    justify-content: center;

    padding: ${props => props.theme.desktopVW(100)} 0;
  }
`

const BannerBackground = styled.img`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
`

const BannerInner = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin: 0 auto;
  padding: calc(${props => props.theme.space.paddingMobile} * 3)
    ${props => props.theme.space.paddingMobile};

  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  a {
    font-family: ${props => props.theme.fontFamily.circularBook} !important;
    line-height: 1.05;
  }

  h1,
  h2,
  h3 {
    font-size: ${props => props.theme.mobileVW(23.5)};
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.desktopVW(50)};

    max-width: ${props => props.theme.space.maxContentWidthDesktop};

    padding: 0;

    text-align: left;

    h1,
    h2,
    h3 {
      font-size: ${props => props.theme.fontSize.d3};
    }
  }
`

const BannerIcon = styled.svg`
  position: relative;

  width: ${props => props.theme.mobileVW(75)};
  height: ${props => props.theme.mobileVW(75)};

  margin: 0 auto ${props => props.theme.mobileVW(15)} auto;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    width: ${props => props.theme.desktopVW(200)};
    height: ${props => props.theme.desktopVW(200)};

    margin-bottom: 0;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin: 0 auto;
  padding: ${props => props.theme.space.paddingMobile}
    ${props => props.theme.space.paddingMobile};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;

    max-width: ${props => props.theme.space.maxContentWidthDesktop};

    padding: 0;
    padding-bottom: ${props => props.theme.desktopVW(200)};
  }
`

const TitleWrapper = styled.div`
  width: 100%;

  text-align: center;

  padding: 8vw 0 16vw 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.desktop}) {
    h2 {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    text-align: left;

    margin-bottom: ${props => props.theme.desktopVW(30)};
    padding: 0;
  }
`

const InstagramWrapper = styled.div`
  width: 100%;

  text-align: left;

  h1,
  h2,
  h3 {
    margin: 0 0 ${props => props.theme.space.paddingMobile} 0;

    font-size: ${props => props.theme.mobileVW(35)};
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    width: 60%;
    max-width: ${props => props.theme.desktopVW(900)};

    h1,
    h2,
    h3 {
      margin-bottom: ${props => props.theme.desktopVW(50)};

      font-size: ${props => props.theme.desktopVW(50)};
    }
  }
`

const Profile = styled.a`
  display: block;

  text-align: right;

  margin-bottom: calc(${props => props.theme.space.paddingMobile} / 2);

  opacity: 0.5;

  transition: opacity 0.25s ease-out;

  font-size: ${props => props.theme.mobileVW(15)};

  &:hover {
    opacity: 1;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: 0;

    text-align: left;

    font-size: ${props => props.theme.desktopVW(20)};
  }
`

const ContactWrapper = styled.div`
  h6 {
    margin-top: ${props => props.theme.mobileVW(15)};

    font-family: ${props => props.theme.fontFamily.brandonMedium};
    font-size: ${props => props.theme.mobileVW(15)};

    &:first-of-type {
      margin-top: 0;
    }
  }

  p,
  a {
    margin-bottom: 0;

    font-size: ${props => props.theme.mobileVW(15)};
    line-height: 1.6;
  }

  a {
    position: relative;

    ${props => props.theme.generateUnderlineAnimation("rgba(0, 0, 0, 0.5)")}
  }

  .button-internal__arrow-wrapper {
    display: none;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    width: 25%;
    margin-left: 15%;

    h6 {
      font-size: ${props => props.theme.desktopVW(22.5)};
      margin-top: ${props => props.theme.desktopVW(20)};

      &:first-of-type {
        margin-top: 0;
      }
    }

    p,
    a {
      font-size: ${props => props.theme.desktopVW(22.5)};
    }
  }
`

const Subfooter = styled.div`
  width: 100%;
  height: ${props => props.theme.mobileVW(50)};

  background-color: ${props => props.theme.colors.white};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    height: ${props => props.theme.desktopVW(150)};
  }
`

const SubInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  margin: 0 auto;
  padding: 0 ${props => props.theme.space.paddingMobile};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.space.maxContentWidthDesktop};

    justify-content: space-between;

    padding: 0;
  }
`

const BottomStyles = css`
  font-size: ${props => props.theme.mobileVW(13)};
  opacity: 0.35;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    font-size: ${props => props.theme.desktopVW(18)};
  }
`

const Copyright = styled.p`
  ${BottomStyles}
`

const BottomLink = styled.a`
    display: none;

    ${BottomStyles}

    margin-right: ${props => props.theme.mobileVW(20)};

    &:last-of-type {
        margin-right: 0;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        display: inline-block;

        margin-right: ${props => props.theme.desktopVW(40)};
    }
`

const Footer = ({
  data: {
    backgroundColor,
    title,
    text,
    bannerTitle,
    bannerImage,
    termsAndConditions,
  },
}) => {
  return (
    <StyledFooter background={backgroundColor && backgroundColor.color}>
      {bannerTitle && bannerImage?.file && (
        <Banner>
          <BannerBackground src={bannerImage.file.url} />
          <BannerInner>
            <BannerIcon
              viewBox="0 0 146 146"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M73.13 111.35C51.92 111.35 34.66 94.09 34.66 72.87C34.66 51.66 51.92 34.4 73.13 34.4C94.34 34.4 111.6 51.66 111.6 72.87C111.6 94.09 94.34 111.35 73.13 111.35ZM73.13 38.58C54.22 38.58 38.83 53.97 38.83 72.88C38.83 91.79 54.22 107.18 73.13 107.18C92.04 107.18 107.43 91.79 107.43 72.88C107.43 53.97 92.04 38.58 73.13 38.58Z"
                fill="white"
              />
              <path d="M108.47 81.53H38.04V85.7H108.47V81.53Z" fill="white" />
              <path
                d="M76.45 72.8801C76.45 74.7101 74.97 76.2001 73.13 76.2001C71.3 76.2001 69.82 74.7201 69.82 72.8801C69.82 71.0501 71.31 69.5601 73.13 69.5601C74.96 69.5601 76.45 71.0501 76.45 72.8801Z"
                fill="white"
              />
              <path
                d="M73.13 139.82C36.22 139.82 6.19 109.79 6.19 72.8799C6.19 35.9699 36.22 5.93994 73.13 5.93994C110.04 5.93994 140.07 35.9699 140.07 72.8799C140.07 109.79 110.04 139.82 73.13 139.82ZM73.13 10.1099C38.52 10.1099 10.37 38.2699 10.37 72.8799C10.37 107.49 38.52 135.65 73.13 135.65C107.74 135.65 135.9 107.49 135.9 72.8799C135.9 38.2699 107.74 10.1099 73.13 10.1099Z"
                fill="white"
              />
              <path
                d="M7.69 72.98V72.94L7.62 64.74L6.76001 64.75C6.76001 64.75 6.70999 71.51 0.799988 72.79C0.809988 72.87 0.820007 72.93 0.820007 72.98C0.820007 73.03 0.809988 73.09 0.799988 73.17C6.70999 74.45 6.76001 81.21 6.76001 81.21L7.62 81.22L7.69 73.02V72.98Z"
                fill="white"
              />
              <path
                d="M138.66 73.14V73.18L138.73 81.38L139.59 81.37C139.59 81.37 139.64 74.61 145.55 73.33C145.54 73.25 145.53 73.19 145.53 73.14C145.53 73.09 145.54 73.03 145.55 72.95C139.64 71.67 139.59 64.91 139.59 64.91L138.73 64.9L138.66 73.1V73.14Z"
                fill="white"
              />
              <path
                d="M73.35 138.31H73.31L65.11 138.38L65.12 139.24C65.12 139.24 71.88 139.29 73.16 145.2C73.24 145.19 73.3 145.18 73.35 145.18C73.4 145.19 73.46 145.19 73.54 145.2C74.82 139.29 81.58 139.24 81.58 139.24L81.59 138.38L73.39 138.31H73.35Z"
                fill="white"
              />
              <path
                d="M73.23 7.39001H73.27L81.47 7.32001L81.46 6.46002C81.46 6.46002 74.7 6.41 73.42 0.5C73.34 0.51 73.28 0.52002 73.23 0.52002C73.18 0.52002 73.12 0.51 73.04 0.5C71.76 6.41 65 6.46002 65 6.46002L64.99 7.32001L73.19 7.39001H73.23Z"
                fill="white"
              />
            </BannerIcon>
            <div>
              <ScrollAnimation animateIn="fadeIn" delay={100} duration={1.5}>
                <Text data={bannerTitle} />
              </ScrollAnimation>
            </div>
          </BannerInner>
        </Banner>
      )}
      <Inner>
        <TitleWrapper>
          <ScrollAnimation animateIn="fadeIn" delay={100} duration={1.5}>
            <Text data={title} />
          </ScrollAnimation>
        </TitleWrapper>
        <InstagramWrapper>
          <InstagramFeed />
          <ScrollAnimation animateIn="fadeIn" delay={6 * 100} duration={1.5}>
            <Profile
              href="https://www.instagram.com/sabinedevoogd/"
              target="_blank"
            >
              @sabinedevoogd
            </Profile>
          </ScrollAnimation>
        </InstagramWrapper>
        <ContactWrapper>
          <ScrollAnimation animateIn="fadeIn" delay={6 * 100}>
            <Text data={text} />
          </ScrollAnimation>
        </ContactWrapper>
      </Inner>
      <Subfooter>
        <SubInner>
          <Copyright>
            &copy; {new Date().getFullYear()} van aard. All Rights Reserved.
          </Copyright>
          <BottomLink href={termsAndConditions.file.url} target="_blank">
            Algemene voorwaarden
          </BottomLink>
        </SubInner>
      </Subfooter>
    </StyledFooter>
  )
}

export default Footer
