import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

import BrandonGrotesqueLight from "../../fonts/BrandonGrotesque-Light.woff"
import BrandonGrotesqueRegular from "../../fonts/BrandonGrotesque-Regular.woff"
import BrandonGrotesqueMedium from "../../fonts/BrandonGrotesque-Medium.woff"

import PortraitLight from "../../fonts/Portrait-Light.woff"
import PortraitLightItalic from "../../fonts/Portrait-LightItalic.woff"
import PortraitRegular from "../../fonts/Portrait-Regular.woff"
import PortraitRegularItalic from "../../fonts/Portrait-RegularItalic.woff"
import PortraitMedium from "../../fonts/Portrait-Medium.woff"
import PortraitMediumItalic from "../../fonts/Portrait-MediumItalic.woff"

import CircularBook from "../../fonts/CircularXX-Book.woff"

const GlobalStyle = createGlobalStyle`
    ${reset}

    @font-face {
      font-family: 'BrandonGrotesqueLight';
      src: url('${BrandonGrotesqueLight}') format('woff');
      font-style: light;
  }

  @font-face {
      font-family: 'BrandonGrotesqueRegular';
      src: url('${BrandonGrotesqueRegular}') format('woff');
      font-style: regular;
  }

  @font-face {
      font-family: 'BrandonGrotesqueMedium';
      src: url('${BrandonGrotesqueMedium}') format('woff');
      font-style: medium;
  }

  @font-face {
      font-family: 'PortraitLight';
      src: url('${PortraitLight}') format('woff');
      font-style: light;
  } 
  
  @font-face {
      font-family: 'PortraitLightItalic';
      src: url('${PortraitLightItalic}') format('woff');
      font-style: light;
  }

  @font-face {
      font-family: 'PortraitRegular';
      src: url('${PortraitRegular}') format('woff');
      font-style: regular;
  } 
  
  @font-face {
      font-family: 'PortraitRegularItalic';
      src: url('${PortraitRegularItalic}') format('woff');
      font-style: regular;
  }

  @font-face {
      font-family: 'PortraitMedium';
      src: url('${PortraitMedium}') format('woff');
      font-style: medium;
  } 
  
  @font-face {
      font-family: 'PortraitMediumItalic';
      src: url('${PortraitMediumItalic}') format('woff');
      font-style: medium;
  }

  @font-face {
    font-family: 'CircularBook';
    src: url('${CircularBook}') format('woff');
    font-style: regular;
}
    
    body {
      font-family: 'PortraitRegular', Arial, sans-erif;
        // font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${props => props.theme.colors.black};
        overflow: ${props => (props.shouldDisableScroll ? "hidden" : "auto")};
        --menu-color: ${props => props.menuColor};
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    
    *:focus {
        outline:none;
    }

    h1 {
      font-family: ${props => props.theme.fontFamily.portraitMedium};
      font-size: ${props => props.theme.fontSize.m1};
      letter-spacing: 0.5;
      line-height: 1;
  }

  h2 {
      font-family: ${props => props.theme.fontFamily.portraitMedium};
      font-size: ${props => props.theme.fontSize.m3};
      letter-spacing: 0.5;
      line-height: 1;
  }

  h3 {
      font-family: ${props => props.theme.fontFamily.portraitMedium};
      font-size: ${props => props.theme.fontSize.m3};
      letter-spacing: 0.5;
      line-height: 1.3;
  }

  h4 {
      font-family: ${props => props.theme.fontFamily.portraitMedium};
      font-size: ${props => props.theme.fontSize.m4};
      letter-spacing: 0.5;
      line-height: 1.3;
  }

  h5 {
      font-family: ${props => props.theme.fontFamily.portraitRegular};
      font-size: ${props => props.theme.fontSize.m4};
      letter-spacing: 0.5;
      line-height: 1.5;
  }

  h6 {
      font-family: ${props => props.theme.fontFamily.portraitRegular};
      font-size: ${props => props.theme.fontSize.m5};
      letter-spacing: 0.5;
      line-height: 1.5;
  }

  p {
      font-family: ${props => props.theme.fontFamily.brandonRegular};
      font-size: ${props => props.theme.fontSize.m6};
      letter-spacing: 0.5;
      line-height: 1.5;

      a {
          opacity: 0.5;
      }
  }

  a {
      font-family: ${props => props.theme.fontFamily.brandonRegular};
      font-size: ${props => props.theme.fontSize.m6};
      letter-spacing: 0.5;
      line-height: 1.5;
  }

  b, strong {
      font-weight: 900;
  }

  i, italic {
      font-style: italic;
  }

  a {
      color: inherit;
      text-decoration: none;
  }

  button,
  input {
      border: 0;
      background: transparent;
      margin: 0;
      padding: 0;
      outline: 0;
      -webkit-font-smoothing: antialiased;
  }
  
  input,
  textarea,
  button,
  select,
  div,
  a {
      -webkit-tap-highlight-color: transparent;
  }

  main {
      width: 100%;
      height: 100%;
  }

  @-webkit-keyframes fadeInUpNew {
      0% {
          opacity: 0;
          -webkit-transform: translate3d(0,10%,0);
          transform: translate3d(0,10%,0)
      }

      to {
          opacity: 1;
          -webkit-transform: translateZ(0);
          transform: translateZ(0)
      }
  }

  @keyframes fadeInUpNew {
      0% {
          opacity: 0;
          -webkit-transform: translate3d(0,10%,0);
          transform: translate3d(0,10%,0)
      }

      to {
          opacity: 1;
          -webkit-transform: translateZ(0);
          transform: translateZ(0)
      }
  }

  .fadeInUp {
      -webkit-animation-name: fadeInUpNew !important;
      animation-name: fadeInUpNew !important;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
      h1 {
          font-size: ${props => props.theme.fontSize.d1};
          line-height: 1;
      }

      h2 {
          font-size: ${props => props.theme.fontSize.d2};
          line-height: 1;
      }

      h3 {
          font-size: ${props => props.theme.fontSize.d3};
      }

      h4 {
          font-size: ${props => props.theme.fontSize.d4};
      }

      h5 {
          font-size: ${props => props.theme.fontSize.d5};
      }

      h6 {
          font-size: ${props => props.theme.fontSize.d6};
      }

      p {
          font-size: ${props => props.theme.fontSize.d6};
      }

      a {
          font-size: ${props => props.theme.fontSize.d6};
      }
  }
`

export default GlobalStyle
