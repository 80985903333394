import React from "react"
import styled from "styled-components"

const List = styled.ul`
  position: absolute;

  left: ${props => props.theme.mobileVW(30)};
  bottom: ${props => props.theme.mobileVW(30)};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    left: ${props => props.theme.desktopVW(100)};
    bottom: 10vh;
  }
`

const Item = styled.li`
  display: block;
  margin-bottom: 1vh;
  margin-left: 0;

  color: ${props => props.theme.colors.white};

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: 1vh;
  }
`

const Link = styled.a`
  color: currentColor;

  font-family: ${props => props.theme.fontFamily.brandonRegular};
  font-size: ${props => props.theme.mobileVW(15)};

  opacity: 0.5;

  ${props => props.theme.generateUnderlineAnimation(props.theme.colors.white)};

  transition: opacity 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    opacity: 1;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    font-size: ${props => props.theme.desktopVW(20)};
  }
`

const SocialList = ({ data }) => {
  return (
    <List>
      {data.edges.map(({ node }, index) => (
        <Item key={index}>
          <Link
            href={node.link}
            target={node.name == "Email" ? "_self" : "_blank"}
          >
            {node.name == "Email" ? "Stuur een email" : node.name}
          </Link>
        </Item>
      ))}
    </List>
  )
}

export default SocialList
