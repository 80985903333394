import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { INLINES } from "@contentful/rich-text-types"
import {
  documentToReactComponents,
  HEADING_1,
  HEADING_2,
  HEADING_3,
  HEADING_4,
  HEADING_5,
  PARAGRAPH,
  HYPERLINK,
  ENTRY_HYPERLINK,
} from "@contentful/rich-text-react-renderer"
import { scroller } from "react-scroll"

import ButtonInternal from "./Buttons/ButtonInternal"

const StyledText = styled.div`
  color: ${props => props.color};

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: ${props => props.theme.mobileVW(15)};
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  blockquote {
    margin-bottom: ${props => props.theme.mobileVW(15)};

    p {
      font-family: ${props => props.theme.fontFamily.portraitMedium};
      font-size: ${props => props.theme.fontSize.m1};
      letter-spacing: 0.5;
      line-height: 1;
    }
  }

  .button-internal {
    display: inline-block;

    margin-bottom: 0;
    margin-right: ${props => props.theme.mobileVW(15)};
  }

  ul,
  ol {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1rem; /* Adjust as needed for alignment */
    list-style-position: outside;
  }

  ul {
    list-style-type: disc; /* Default style for unordered lists */
  }

  ol {
    list-style-type: decimal; /* Default style for ordered lists */
  }

  ul li,
  ol li {
    padding-left: 0.25rem;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin-bottom: ${props => props.theme.desktopVW(20)};
    }

    p:empty {
      display: block;
      margin-bottom: ${props => props.theme.desktopVW(40)};
    }

    blockquote {
      margin-bottom: ${props => props.theme.desktopVW(20)};

      p {
        font-size: ${props => props.theme.fontSize.d1};
        line-height: 1;
      }
    }

    .button-internal {
      margin-right: ${props => props.theme.desktopVW(15)};
    }
  }
`

const getHyperlink = (node, children, color) => {
  if (
    node.data.uri.includes("mailto") ||
    node.data.uri.includes("tel") ||
    node.data.uri.includes("http")
  ) {
    return (
      <ButtonInternal
        label={children[0]}
        href={node.data.uri}
        color={color && color.color}
      />
    )
  } else if (node.data.uri.includes("#")) {
    return (
      <ButtonInternal
        label={children[0]}
        onClick={() => {
          scroller.scrollTo(node.data.uri.replace("#", ""), {
            duration: 1500,
            delay: 100,
            smooth: "easeInOutQuint",
          })
        }}
        color={color && color.color}
      />
    )
  } else {
    return (
      <ButtonInternal
        label={children[0]}
        to={node.data.uri}
        color={color && color.color}
      />
    )
  }
}

const Text = ({ data, className, color }) => {
  if (data && data.json) {
    return (
      <StyledText
        color={color && color}
        className={`${className && className} text-wrapper`}
      >
        {documentToReactComponents(data.json, {
          renderNode: {
            [HEADING_1]: (node, children) => <h1>{children}</h1>,
            [HEADING_2]: (node, children) => <h2>{children}</h2>,
            [HEADING_3]: (node, children) => <h3>{children}</h3>,
            [HEADING_4]: (node, children) => <h4>{children}</h4>,
            [HEADING_5]: (node, children) => <h5>{children}</h5>,
            [PARAGRAPH]: (node, children) => <p>{children}</p>,
            [INLINES.HYPERLINK]: (node, children) =>
              getHyperlink(node, children, color),
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
              const slug = node.data.target.fields.slug.nl
              return <Link to={slug}>{children}</Link>
            },
          },
        })}
      </StyledText>
    )
  } else {
    return null
  }
}

export default Text
