import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import AniLink from "gatsby-plugin-transition-link/AniLink"

const StyledLogo = styled(AniLink)`
  // position: absolute;
  position: relative;
  display: block;

  // top: ${props => props.theme.mobileVW(25)};
  // right: ${props => props.theme.mobileVW(25)};

  width: ${props => props.theme.mobileVW(125)};

  font-family: ${props => props.theme.fontFamily.portraitRegular};
  font-size: ${props => props.theme.mobileVW(25)};
  line-height: 1;

  color: ${props => props.color || props.theme.colors.black};

  transition: all 0.35s ease-out;

  @media screen and (max-width: ${props => props.theme.breakpoints.desktop}) {
    ${({ open }) =>
      open &&
      `
            color: #fff;
        `}
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    // position: absolute;

    // top: ${props => props.theme.desktopVW(80)};
    // right: ${props => props.theme.desktopVW(100)};

    width: ${props => props.theme.desktopVW(175)};

    font-size: ${props => props.theme.desktopVW(38)};
  }
`

const Cursive = styled.span`
  font-family: ${props => props.theme.fontFamily.portraitRegularItalic};
`

const vanAardLogoSvg = (
  <svg viewBox="0 0 378 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M350.19 65.78H361.62C361.407 52.7942 361.432 39.8258 361.457 26.8646V26.8512C361.474 17.8985 361.491 8.94925 361.43 0H349.51V25.43C348.36 23.64 346.92 22.47 345.19 21.66C341.01 19.7 336.63 19.51 332.22 20.48C325.48 21.97 320.62 26.01 317.82 32.23C314.13 40.44 314.48 48.63 318.87 56.55C320.77 59.98 323.55 62.55 326.99 64.41C330.86 66.5 335.01 67.14 339.35 66.77C343.15 66.45 346.3 64.81 348.84 61.99C349.058 61.7382 349.252 61.4632 349.452 61.1808C349.546 61.0484 349.641 60.914 349.74 60.78C349.83 61.7493 349.914 62.6931 349.997 63.6259C350.061 64.3484 350.125 65.0642 350.19 65.78ZM349.687 42.3871C349.718 42.6886 349.748 42.9897 349.78 43.29C349.7 45.95 349.28 48.52 347.89 50.81C345.66 54.48 342.34 56.21 338.07 56.04C335.41 55.94 333.06 54.98 331.14 53.1C329.41 51.42 328.39 49.34 327.95 46.99C327.42 44.18 327.44 41.39 328.24 38.62C329.82 33.17 335.25 29.89 340.81 30.97C345.36 31.86 348.75 35.34 349.44 40.19C349.539 40.9197 349.613 41.6544 349.687 42.3871Z"
      fill="currentColor"
    />
    <path
      d="M134.96 65.79H147.07V65.04C147.07 62.0467 147.071 59.0534 147.072 56.06V56.0293C147.074 50.0528 147.077 44.0764 147.07 38.1C147.06 35.81 146.86 33.53 146.28 31.3C145.17 27 142.79 23.67 138.79 21.63C135.31 19.85 131.6 19.49 127.78 20.2C124.34 20.84 121.3 22.25 119.05 25.04C118.815 25.3287 118.589 25.6353 118.33 25.9873C118.203 26.1596 118.068 26.3428 117.92 26.54V21.11H106.24V65.75H118.29V64.82C118.29 62.8105 118.289 60.8004 118.289 58.79V58.7863C118.287 52.7445 118.285 46.699 118.3 40.66C118.3 39.71 118.35 38.76 118.52 37.83C118.98 35.4 120.09 33.34 122.22 31.96C123.93 30.85 125.86 30.68 127.83 30.82C131.04 31.04 133.32 32.85 134.35 35.89C134.84 37.32 134.96 38.78 134.96 40.26V65.79Z"
      fill="currentColor"
    />
    <path
      d="M0 21.07H0.720001C1.7101 21.07 2.7002 21.0707 3.69045 21.0713H3.69186C6.66145 21.0732 9.63338 21.0751 12.61 21.0601C13.14 21.0601 13.36 21.23 13.54 21.72C17.0491 31.2477 20.5783 40.7654 24.1074 50.2831L24.1087 50.2866L24.11 50.29C24.19 50.5 24.28 50.7 24.43 51.03C24.8007 49.9805 25.1644 48.9552 25.5234 47.9434C26.0262 46.526 26.5199 45.1345 27.01 43.7401C28.3103 40.0691 29.6107 36.3956 30.911 32.7221C32.2107 29.0506 33.5103 25.3791 34.81 21.7101L34.8245 21.6676C34.9372 21.3361 35.0312 21.0601 35.53 21.0601C38.0043 21.0788 40.4785 21.078 42.9552 21.0772H42.9564C44.4431 21.0768 45.931 21.0763 47.42 21.08C47.4935 21.08 47.567 21.0934 47.684 21.1146L47.77 21.13C47.7228 21.2527 47.6801 21.3687 47.6387 21.4812C47.5923 21.607 47.5475 21.7286 47.5 21.85C45.4425 27.1535 43.3835 32.4555 41.3248 37.7572L41.321 37.767L41.3106 37.7938L41.3092 37.7975C37.7598 46.9377 34.2109 56.0769 30.67 65.22C30.49 65.68 30.29 65.84 29.79 65.83C26.9291 65.8151 24.0682 65.8169 21.1905 65.8188H21.1821C20.2201 65.8194 19.2563 65.82 18.29 65.82C12.19 50.91 6.11 36.03 0 21.07Z"
      fill="currentColor"
    />
    <path
      d="M296.84 21.1H285.17V21.2358C285.17 36.1301 285.17 50.955 285.16 65.76H297.26V45.26C297.26 42.86 297.56 40.53 298.64 38.34C299.58 36.43 300.9 34.94 302.82 33.98C305.35 32.7 308.05 32.58 310.8 32.82C311.156 32.8529 311.508 32.9033 311.86 32.9538C312.043 32.9799 312.226 33.0061 312.41 33.03V20.86C309.34 20.48 306.37 20.7 303.49 21.73C300.57 22.77 298.34 24.66 296.84 27.42V21.1Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.89 26.33V21.11H95.59C95.667 27.1377 95.6455 33.1638 95.6239 39.1929C95.5922 48.0372 95.5605 56.888 95.84 65.76H84.32C84.2296 64.6331 84.1392 63.4916 84.0467 62.3225C83.9856 61.5516 83.9236 60.7687 83.86 59.97C83.7491 60.1506 83.6462 60.3262 83.5463 60.4968C83.3309 60.8646 83.1291 61.2089 82.89 61.53C79.98 65.43 76.01 67.12 71.22 67.08C65.87 67.04 61.12 65.35 57.09 61.83C54.78 59.81 53.04 57.33 51.78 54.53C49.8 50.13 49.13 45.49 49.56 40.72C50.1 34.72 52.32 29.43 56.75 25.21C59.35 22.73 62.47 21.11 65.97 20.35C70.71 19.33 75.35 19.49 79.68 22.03C81.23 22.94 82.46 24.17 83.36 25.73C83.49 25.95 83.62 26.16 83.76 26.37C83.8 26.35 83.84 26.34 83.89 26.33ZM61.66 43.19C61.72 46.7 62.37 49.95 64.59 52.74C67.54 56.45 72.29 57.41 76.3 56.03C79.93 54.78 82.1 52.03 83.17 48.41C84.02 45.52 84.05 42.56 83.54 39.62C83.02 36.63 81.66 34.09 79.16 32.23C76.92 30.57 74.38 30.07 71.67 30.31C68.08 30.63 65.35 32.38 63.49 35.46C62.05 37.85 61.72 40.52 61.66 43.19Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M208.75 21.1V26.32C208.7 26.33 208.65 26.34 208.61 26.36C208.579 26.308 208.548 26.2552 208.516 26.2019C208.496 26.1679 208.475 26.1337 208.455 26.0995C208.402 26.0124 208.35 25.9247 208.3 25.84C207.36 24.27 206.14 22.98 204.54 22.03C200.85 19.84 196.86 19.46 192.73 20.01C187.69 20.68 183.45 22.94 180.11 26.78C176.63 30.78 174.85 35.52 174.42 40.74C174.09 44.66 174.43 48.54 175.76 52.3C177.05 55.94 178.95 59.22 181.86 61.77C186.66 65.97 192.33 67.68 198.66 66.9C202.99 66.37 206.29 64.13 208.49 60.33C208.54 60.2365 208.606 60.1534 208.673 60.0695L208.711 60.0213C208.724 60.0043 208.737 59.9873 208.75 59.97C208.841 61.1629 208.924 62.3374 209.007 63.5026C209.061 64.2616 209.115 65.0166 209.17 65.77H220.72C220.408 56.8239 220.442 47.8921 220.475 38.9618C220.498 33.0088 220.52 27.0556 220.44 21.1H208.75ZM188.49 51.31C187.09 48.94 186.51 46.43 186.5 43.27C186.58 40.47 186.91 37.74 188.45 35.3C190.31 32.35 192.96 30.63 196.44 30.31C199.2 30.05 201.79 30.53 204.06 32.25C206.56 34.14 207.9 36.7 208.42 39.72C208.93 42.65 208.91 45.59 208.02 48.44C206.7 52.71 204.01 55.7 199.44 56.44C194.99 57.16 190.98 55.54 188.49 51.31Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M274.75 65.7801H263.27C263.211 64.9632 263.15 64.1463 263.089 63.3256L263.089 63.3188C263.005 62.1928 262.921 61.0598 262.84 59.9101C262.727 60.0938 262.619 60.2812 262.511 60.4685L262.51 60.4696C262.313 60.812 262.116 61.1535 261.89 61.4701C259.04 65.3401 255.12 67.0701 250.38 67.0701C245.99 67.0701 241.92 65.9801 238.25 63.5101C234.87 61.2401 232.43 58.1901 230.75 54.5101C228.78 50.1801 228.14 45.6001 228.51 40.9001C228.92 35.6501 230.67 30.8901 234.13 26.8401C237 23.4801 240.63 21.2801 244.94 20.3301C249.7 19.2901 254.36 19.4801 258.71 22.0401C260.46 23.0701 261.7 24.5601 262.8 26.2801V21.1001H274.59C274.643 27.171 274.626 33.2369 274.609 39.3046V39.3059C274.585 48.1199 274.56 56.9377 274.75 65.7801ZM262.81 42.4602L262.81 42.4579L262.81 42.4566C262.779 41.9407 262.742 41.3026 262.66 40.6701C262.28 37.5701 261.16 34.8301 258.74 32.7201C256.39 30.6701 253.61 30.0101 250.6 30.3101C246.35 30.7301 243.34 32.9901 241.75 36.9101C240.18 40.7701 240.21 44.7901 241.43 48.7301C242.94 53.6101 247.12 57.0301 252.79 56.5201C256.38 56.2001 258.98 54.3701 260.82 51.3901C262.38 48.9101 262.78 46.1101 262.84 42.9401C262.83 42.7978 262.82 42.6361 262.81 42.4602Z"
      fill="currentColor"
    />
    <path
      d="M371.778 8.36879C371.365 9.41247 370.66 11.1963 370.66 11.7901C370.66 12.7501 371.3 13.4301 372.34 13.4301C373.54 13.4301 374.02 12.6301 374.02 11.7901C374.02 10.9867 373.32 9.36017 372.895 8.37182C372.728 7.98227 372.603 7.69186 372.58 7.59009L372.78 7.47009C372.806 7.49636 372.923 7.64147 373.096 7.85758C373.714 8.62872 375.058 10.3039 375.62 10.7101C376.38 11.2701 377.46 11.0301 377.94 10.2301C378.5 9.27009 378.02 8.19009 377.5 7.95009C376.672 7.55424 373.837 7.12602 373.102 7.01494C373.02 7.00255 372.964 6.99411 372.94 6.9901V6.79008C373.18 6.79008 376.74 6.31009 377.22 6.03009C378.02 5.59009 378.26 4.63008 377.82 3.79008C377.34 2.91008 376.54 2.71009 375.7 3.15009C375.171 3.43241 373.458 5.55173 372.925 6.21168C372.854 6.29975 372.804 6.36183 372.78 6.39009L372.58 6.23009C372.7 5.99009 374.02 2.7101 374.02 1.9101C374.02 0.950095 373.34 0.470093 372.34 0.470093C371.3 0.470093 370.66 1.07009 370.66 2.11009C370.66 2.72799 371.447 4.63442 371.861 5.63622C371.983 5.93147 372.073 6.14814 372.1 6.23009L371.94 6.35008C371.78 6.15008 369.22 3.35009 368.74 3.11009C368.14 2.79009 367.22 2.63009 366.62 3.83009C366.06 4.91009 366.5 5.63009 367.34 5.95009C367.8 6.13393 369.879 6.48205 371.006 6.67087C371.351 6.72855 371.606 6.77136 371.7 6.79008V6.9901C371.617 7.01081 371.322 7.06906 370.922 7.14819C369.776 7.37469 367.765 7.77223 367.38 7.95009C366.78 8.23009 366.14 9.31009 366.74 10.2301C367.3 11.1101 368.18 11.3101 368.82 10.9101C369.275 10.626 370.375 9.31313 371.16 8.37537C371.481 7.99264 371.749 7.6724 371.9 7.51009L372.06 7.6301C372.029 7.7332 371.921 8.00637 371.778 8.36879Z"
      fill="currentColor"
    />
  </svg>
)

const rootsmentLogoSvg = (
  <svg viewBox="0 0 438 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M400.08 0.419922H411.18V13.9199H420.3V24.8199H411.26C411.255 24.8805 411.25 24.9389 411.245 24.9955L411.244 25.0163C411.22 25.2964 411.2 25.5334 411.2 25.77C411.2 27.2782 411.198 28.7865 411.196 30.2948C411.191 34.8165 411.185 39.3382 411.23 43.8599C411.24 44.9299 411.43 46.0999 411.88 47.0499C412.62 48.5899 414.18 48.9899 415.77 49.0699C416.818 49.1192 417.871 49.1091 418.946 49.0987C419.4 49.0944 419.857 49.09 420.32 49.09C420.33 49.16 420.36 49.4199 420.36 49.6799C420.36 50.3757 420.361 51.0715 420.362 51.7673V51.773C420.366 53.8553 420.37 55.9376 420.34 58.02C420.33 58.39 420.05 59.01 419.77 59.09C415.1 60.45 410.43 60.5499 405.97 58.4399C401.78 56.4599 399.65 52.92 399.15 48.39C398.97 46.7187 398.961 45.0295 398.951 43.3479L398.95 43.08C398.935 38.8111 398.937 34.5365 398.939 30.2648V30.2629C398.939 28.8415 398.94 27.4204 398.94 25.9999V24.84H390.84V13.9299H392.91C397.44 13.9199 400.06 11.3099 400.1 6.75995C400.09 4.67995 400.08 2.56992 400.08 0.419922Z"
      fill="currentColor"
    />
    <path
      d="M175.19 45.32C173.08 45.79 170.97 46.26 168.83 46.73C168.844 46.8921 168.851 47.037 168.858 47.1727L168.858 47.1739C168.866 47.3308 168.874 47.4754 168.89 47.62C170.12 53.41 173.74 57.14 179.11 59.3C182.97 60.86 187.01 61.13 191.07 60.55C196.87 59.72 201.59 57.17 204.18 51.58C206.95 45.61 204.94 38.48 199.45 34.84C196.57 32.93 193.3 32.15 189.98 31.45C189.598 31.3697 189.215 31.2923 188.83 31.2149L188.829 31.2146C187.11 30.8681 185.38 30.5192 183.77 29.89C180.56 28.64 180.77 25.5 182.36 23.78C183.56 22.5 185.14 22.03 186.88 21.94C190.61 21.74 193.36 23.64 194.39 27.2C194.442 27.3814 194.51 27.5544 194.577 27.7217C194.613 27.8126 194.648 27.902 194.68 27.99C196.737 27.5222 198.762 27.0652 200.787 26.6082C202.138 26.3032 203.489 25.9982 204.85 25.69C204.28 21.97 202.69 18.87 199.79 16.66C193.73 12.04 186.97 11.41 179.95 13.96C174.57 15.91 171.05 19.77 170.18 25.54C169.43 30.55 171.12 34.75 175.24 37.86C177.55 39.59 180.23 40.38 182.97 41.06C183.706 41.2418 184.444 41.418 185.183 41.5943C187.044 42.0384 188.908 42.4832 190.74 43.02C192.64 43.58 193.59 44.97 193.65 46.78C193.7 48.46 192.64 50 190.93 50.75C189.27 51.48 187.54 51.43 185.8 51.2C182.5 50.76 179.99 48.37 179.49 45.14C179.39 44.52 179.11 44.44 178.61 44.55C178.04 44.675 177.47 44.805 176.9 44.935C176.33 45.065 175.76 45.195 175.19 45.32Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M302.78 39.89H335.19C335.41 36.57 335.44 33.41 334.89 30.26C334 25.28 331.99 20.88 328.07 17.56C322.76 13.06 316.42 11.8799 309.78 12.8499C298.65 14.4799 291.54 23.36 290.65 34.02C290.22 39.16 290.94 44.14 293.41 48.78C295.36 52.44 298.05 55.4 301.63 57.48C306.25 60.17 311.27 61.15 316.6 60.62C322.71 60.01 327.82 57.53 331.6 52.61C333.03 50.76 334.1 48.7099 334.69 46.3499C333.205 45.9095 331.73 45.4728 330.263 45.0382C328.333 44.4664 326.415 43.8982 324.5 43.33C321.71 50.3 315.52 50.98 310.7 49.73C305.67 48.42 302.49 43.38 302.78 39.89ZM312.03 22.58C318.81 22.15 323.03 26.1299 323.18 31.4099H303.11C302.75 27.4099 307.42 22.87 312.03 22.58Z"
      fill="currentColor"
    />
    <path
      d="M213.61 59.35H225.82V58.07C225.82 55.3193 225.819 52.5696 225.818 49.8207V49.7748C225.816 44.2946 225.813 38.8173 225.82 33.34C225.82 28.05 228.47 24.06 233.86 23.87C237.68 23.74 240.39 24.86 241.71 28.72C242.19 30.14 242.34 31.73 242.35 33.25C242.395 39.4597 242.389 45.6637 242.384 51.8706C242.382 53.94 242.38 56.0097 242.38 58.08V59.29H254.57V33.2C254.57 30.57 255.2 28.13 257.07 26.19C259.37 23.8 262.27 23.53 265.35 24.08C267.79 24.52 269.46 25.95 270.21 28.23C270.71 29.76 271 31.43 271.01 33.04C271.07 39.2949 271.062 45.5554 271.055 51.8132V51.8207C271.052 53.9042 271.05 55.9874 271.05 58.07V59.3H282.96V58.08C282.96 55.8655 282.961 53.6516 282.961 51.4381V51.4306C282.963 44.8004 282.965 38.173 282.95 31.54C282.94 29.94 282.91 28.32 282.73 26.73C282.22 22.19 280.28 18.4 276.56 15.66C273.65 13.52 270.29 12.74 266.72 12.69C261.65 12.63 257.22 14.1 253.77 17.98C253.375 18.4287 253.016 18.9089 252.644 19.4055L252.643 19.4065L252.643 19.4073C252.46 19.6515 252.274 19.8997 252.08 20.15C249.26 14.93 244.76 12.77 239.12 12.73C233.59 12.69 228.84 14.44 225.28 19.3V14H213.61V59.35Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.66 37.45C83.77 30.61 85.37 25.49 88.92 21.1C92.62 16.52 97.5 13.88 103.31 12.93C107.87 12.18 112.33 12.56 116.63 14.26C122.43 16.54 126.71 20.47 129.2 26.22C132.02 32.75 132.21 39.43 129.63 46.0201C126.64 53.6501 120.98 58.4 112.95 60.16C103.33 62.27 92.92 58.77 87.46 50.12C84.77 45.88 83.64 41.22 83.66 37.45ZM106.91 23.7701C103.91 23.8 100.73 25.0501 98.67 28.0301C95.09 33.2201 94.93 38.78 98 44.26C100.18 48.16 103.84 49.7501 108.22 49.5301C112.37 49.3201 115.5 47.32 117.47 43.64C119.04 40.7 119.33 37.55 118.91 34.29C118.14 28.4 113.95 23.8 106.91 23.7701Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.99 52.51C76.75 47.9 78.51 42.57 78.47 36.64C78.45 32.82 77.74 29.17 76.09 25.71C73.45 20.15 69.23 16.34 63.51 14.19C58.37 12.25 53.12 12.06 47.83 13.52C41.38 15.31 36.5 19.13 33.48 25.11C30.54 30.92 30.03 37.08 31.67 43.35C33.65 50.92 38.32 56.29 45.64 59.12C51.83 61.51 58.1 61.3699 64.25 58.91C67.69 57.5299 70.64 55.39 72.99 52.51ZM43.1327 37.4101C43.1286 37.2786 43.1249 37.1603 43.12 37.06C43.07 32.86 44.11 29.33 47.05 26.63C52.12 21.95 61.14 22.97 64.48 29.56C67.01 34.55 67 39.6699 64.06 44.4699C60.77 49.8399 53.41 51.22 48.21 47.64C45.16 45.54 43.69 42.47 43.23 38.87C43.1621 38.3491 43.1454 37.8161 43.1327 37.4101Z"
      fill="currentColor"
    />
    <path
      d="M343.45 13.97H355.34V19.14C355.746 18.7535 356.131 18.3607 356.51 17.9743L356.511 17.9729C357.308 17.1605 358.076 16.3768 358.95 15.74C365.4 11.08 376.48 11.61 381.7 18.99C383.79 21.95 384.77 25.33 384.81 28.87C384.912 36.1318 384.913 43.399 384.914 50.6676V50.6741C384.914 53.3526 384.915 56.0313 384.92 58.71C384.92 58.8352 384.898 58.9648 384.875 59.1044L384.863 59.1749C384.855 59.2252 384.847 59.2768 384.84 59.33H372.62V33.62C372.62 31.6 372.41 29.62 371.5 27.78C370.1 24.96 367.63 23.95 364.68 23.83C359.27 23.6 356.02 27.25 355.88 31.94C355.698 38.0245 355.712 44.114 355.727 50.2014V50.2027C355.733 52.7887 355.739 55.3753 355.73 57.96V59.31H343.44C343.45 44.24 343.45 29.16 343.45 13.97Z"
      fill="currentColor"
    />
    <path
      d="M134.29 24.8099H142.45V26.0099C142.45 27.6193 142.449 29.2286 142.449 30.838V30.851C142.447 35.6673 142.445 40.4836 142.46 45.2999C142.46 46.4699 142.51 47.6399 142.67 48.7999C143.15 52.2899 144.63 55.2699 147.59 57.3099C152.66 60.7999 158.15 60.4399 163.76 59.0399V49.08C163.254 49.08 162.754 49.086 162.258 49.0921C161.196 49.105 160.153 49.1177 159.11 49.0699C156.58 48.9499 155.25 47.8199 154.79 45.4399C154.695 44.9632 154.697 44.4639 154.699 43.9682C154.7 43.8921 154.7 43.8157 154.7 43.7399V25.96C154.7 25.7242 154.713 25.4927 154.727 25.2317C154.735 25.0943 154.743 24.9486 154.75 24.7899H163.74V13.9H154.68V0.449951H143.47C143.47 1.23839 143.483 2.01905 143.495 2.79376L143.495 2.79565C143.523 4.50624 143.55 6.18781 143.44 7.85992C143.24 10.9499 141.25 13.55 137.8 13.83C136.948 13.9034 136.096 13.9337 135.233 13.9644C134.92 13.9755 134.606 13.9867 134.29 13.9999V24.8099Z"
      fill="currentColor"
    />
    <path
      d="M27.83 13.82V26.0999C27.7209 26.0858 27.6143 26.0717 27.5095 26.0578C27.096 26.0029 26.7108 25.9519 26.32 25.92C22.74 25.61 19.26 25.82 16.34 28.21C14.05 30.09 12.9 32.6699 12.65 35.5399C12.4429 37.9344 12.4348 40.3495 12.4268 42.7624C12.4251 43.2617 12.4234 43.761 12.42 44.26C12.3986 47.8191 12.4027 51.3731 12.4067 54.9584C12.4084 56.3997 12.41 57.8462 12.41 59.3H0.200012V14.01H12.02V20.28C13.69 17.27 16.25 15.43 19.38 14.43C22.1 13.55 24.93 13.32 27.83 13.82Z"
      fill="currentColor"
    />
    <path
      d="M431.428 9.02838C431.015 10.072 430.31 11.8562 430.31 12.45C430.31 13.41 430.95 14.09 431.99 14.09C433.19 14.09 433.67 13.29 433.67 12.45C433.67 11.6467 432.97 10.0203 432.545 9.0319C432.378 8.64227 432.253 8.35179 432.23 8.25L432.43 8.13C432.456 8.15626 432.573 8.30123 432.746 8.51717C433.364 9.28815 434.708 10.9638 435.27 11.37C436.03 11.93 437.11 11.69 437.59 10.89C438.15 9.93001 437.67 8.84999 437.15 8.60999C436.322 8.21416 433.488 7.786 432.752 7.67489C432.67 7.66249 432.614 7.65404 432.59 7.65002V7.45001C432.83 7.45001 436.39 6.97 436.87 6.69C437.67 6.25 437.91 5.29001 437.47 4.45001C436.99 3.57001 436.19 3.37 435.35 3.81C434.821 4.09238 433.107 6.21256 432.575 6.87201C432.504 6.95985 432.454 7.02178 432.43 7.04999L432.23 6.89001C432.35 6.65001 433.67 3.37001 433.67 2.57001C433.67 1.61001 432.99 1.13 431.99 1.13C430.95 1.13 430.31 1.73002 430.31 2.77002C430.31 3.38798 431.098 5.29479 431.511 6.29649C431.633 6.59156 431.723 6.8081 431.75 6.89001L431.59 7.01001C431.43 6.81001 428.87 4.01002 428.39 3.77002C427.79 3.45002 426.87 3.28999 426.27 4.48999C425.71 5.56999 426.15 6.28999 426.99 6.60999C427.45 6.79384 429.529 7.14201 430.656 7.33083C431.001 7.38849 431.256 7.4313 431.35 7.45001V7.65002C431.267 7.67074 430.973 7.72898 430.572 7.80809C429.427 8.03457 427.415 8.43212 427.03 8.60999C426.43 8.88999 425.79 9.97001 426.39 10.89C426.95 11.77 427.83 11.97 428.47 11.57C428.924 11.286 430.024 9.97324 430.81 9.03549C431.131 8.65266 431.399 8.33232 431.55 8.16998L431.71 8.28998C431.679 8.39305 431.571 8.6661 431.428 9.02838Z"
      fill="currentColor"
    />
  </svg>
)

const Logo = ({ color, menuOpen, rootsmentLogo, menuColor }) => {
  return (
    <StyledLogo
      className="logo"
      to="/"
      color={color}
      open={menuOpen}
      cover
      direction="right"
      bg={menuColor}
    >
      {rootsmentLogo ? rootsmentLogoSvg : vanAardLogoSvg}
    </StyledLogo>
  )
}

export default Logo
